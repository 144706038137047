import React from 'react'
import "../css/style.css"
function GetinTouch() {
  return (
    <div>
         {/* Get in Touch */}
      <section className="container  d-flex align-items-center justify-content-center touch">
        <div className="row px-xl-5 px-1">
          <div className="col-md-7 col-12 text-start touch-para">
            <h1>Get in Touch</h1>
            <p>Email us with any questions at <a href="/contact" className="text-decoration-none text-white">
                info@checkpointtechnologies.com</a> or use the
              form and one of our representatives will contact you shortly.</p>
          </div>
          <div className="col-lg-5 col-12 d-flex justify-content-lg-between align-items-center touch_btn">
            <a href="tel:+415-910-5009" className="btn btn_call"><i className="fa-solid fa-phone pe-1"></i>480-206-8133</a>
            <a href="/contact" className="btn touch_vector ms-lg-0 ms-4">Contact Us →</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default GetinTouch;