import React from 'react';
import HeaderOne from './header/HeaderOne';
import Footer from './footer/Footer';
import GetinTouch from '../elements/GetinTouch';
const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">          
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <GetinTouch/>
            <Footer/>
             
            </main>
        </>
    )
}
export default Layout;
