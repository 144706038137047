import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "../../css/style.css";
import "../../css/example.css";
import { strapi_base_url } from "../../utils/base_url";

function Nav() {
  const [headerMenu, setHeaderMenu] = useState([]);
  const [product, setProduct] = useState([]);
  const [technologies, setTechnologies] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/menus/?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHeaderMenu(
          responseData.data[0] &&
            responseData.data[0].attributes &&
            responseData.data[0].attributes.items &&
            responseData.data[0].attributes.items.data
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(`${strapi_base_url}/api/products?populate=`);
      const productData = await response.json();
      setProduct(productData.data);
    } catch (error) {
      console.error("Error fetching service pages:", error);
    }
  };

  const fetchTechnologies = async () => {
    try {
      const response = await fetch(
        `${strapi_base_url}/api/technologies?populate=*`
      );
      const TechnologiesData = await response.json();
      setTechnologies(TechnologiesData.data);
    } catch (error) {
      console.error("Error fetching Technologies pages:", error);
    }
  };

  useEffect(() => {
    fetchTechnologies();
    getProducts();
  }, []);

  const handleScrollToEvents = () => {
    const eventsSection = document.getElementById("events");
    if (eventsSection) {
      eventsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderMenu = () => {
    return (
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center align-items-center">
        {headerMenu.map((menuItem) => (
          <li
            key={menuItem.id}
            className={`nav-item ${menuItem ? "dropdown" : ""}`}
          >
            {menuItem.attributes.title === "Events" ? (
              <Link
                className="nav-link"
                to={`${
                  menuItem && menuItem.attributes && menuItem.attributes.url
                }`}
                onClick={handleScrollToEvents}
              >
                {menuItem.attributes.title}
              </Link>
            ) : (
              <Link
                className="nav-link"
                to={`${
                  menuItem && menuItem.attributes && menuItem.attributes.url
                }`}
              >
                {menuItem && menuItem.attributes && menuItem.attributes.title}{" "}
                {menuItem &&
                  menuItem.attributes &&
                  (menuItem.attributes.title === "Infrascan LSM/PEM Systems" ||
                    menuItem.attributes.title === "Applications") && (
                    <i
                      className={`fa-sharp fa-solid fa-caret-down ${
                        menuItem.showDropdown ? "arrow-up" : "arrow-down"
                      }`}
                    ></i>
                  )}
              </Link>
            )}
            {menuItem &&
              menuItem.attributes &&
              menuItem.attributes.title == "Infrascan LSM/PEM Systems" && (
                <ul
                  style={{ background: "#E0000D" }}
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {product &&
                    product.map((submenu) => (
                      <li key={submenu.id}>
                        <a
                          className="dropdown-item"
                          href={`${
                            submenu &&
                            submenu.attributes &&
                            submenu.attributes.slug
                          }`}
                        >
                          {submenu &&
                            submenu.attributes &&
                            submenu.attributes.title}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
            {menuItem &&
              menuItem.attributes &&
              menuItem.attributes.title == "Applications" && (
                <ul
                  style={{ background: "#E0000D" }}
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {technologies &&
                    technologies.map((submenu) => (
                      <li key={submenu.id}>
                        <a
                          className="dropdown-item"
                          href={`${
                            submenu &&
                            submenu.attributes &&
                            submenu.attributes.slug
                          }`}
                        >
                          {submenu &&
                            submenu.attributes &&
                            submenu.attributes.title}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
          </li>
        ))}
      </ul>
    );
  };

  return <>{headerMenu.length > 0 ? renderMenu() : ""}</>;
}

export default Nav;
