import React, { useEffect } from "react";
import "../../css/style.css";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import { strapi_base_url } from "../../utils/base_url";

function ProductTwo({ productData }) {
  useEffect(() => {}, [productData]);

  const ProductImage =
    productData.productImage &&
    productData.productImage.data[0] &&
    productData.productImage.data[0].attributes &&
    productData.productImage.data[0].attributes.url;

  return (
    <div>
      <SEO title={productData.title} description={"Checkpoint || Products"} />
      <Layout>
        <div className="container container_bg d-flex justify-content-center">
          <div className="img_infra d-flex justify-content-center">
            {/* <img src="img/infra_es_4_img.png" className="mt-md-5 mt-0" /> */}
            <img
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "25px",
            }}
              src={`${strapi_base_url}${ProductImage}`}
              className="product_image"
              alt="Product Image"
            />
          </div>
        </div>
        <div
          className="container infra_heading p-4"
          style={{ backgroundColor: "#fdf3f2" }}
        >
          <h1>{productData.title}</h1>
          <p>{productData.productDescription}</p>
        </div>
      </Layout>
    </div>
  );
}

export default ProductTwo;
