import React, { useEffect, useState, useRef } from "react";
import { base_url } from "../../utils/base_url";
import { Link } from "react-router-dom";
import "../../css/style.css";
import "../../css/example.css";
import { strapi_base_url } from "../../utils/base_url";

function Nav() {
  // const [data, setData] = useState([]);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [product, setProduct] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  // useEffect(() => {
  //   fetch(`${base_url}getAllHeaderdata`)
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //       setData(responseData.AllDataHeader);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/menus/?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHeaderMenu(
          responseData.data[0] &&
            responseData.data[0].attributes &&
            responseData.data[0].attributes.items &&
            responseData.data[0].attributes.items.data
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(`${strapi_base_url}/api/products?populate=`);
      const productData = await response.json();
      setProduct(productData.data);
    } catch (error) {
      console.error("Error fetching service pages:", error);
    }
  };

  const fetchTechnologies = async () => {
    try {
      const response = await fetch(
        `${strapi_base_url}/api/technologies?populate=*`
      );
      const TechnologiesData = await response.json();
      setTechnologies(TechnologiesData.data);
    } catch (error) {
      console.error("Error fetching Technologies pages:", error);
    }
  };

  useEffect(() => {
    fetchTechnologies();
    getProducts();
  }, []);

  // const renderSubMenu = (subMenuItems) => {
  //   return (
  //     <ul
  //       style={{ background: "#E0000D" }}
  //       className="dropdown-menu"
  //       aria-labelledby="dropdownMenuButton"
  //     >
  //       {subMenuItems.map((subMenuItem) => (
  //         <li key={subMenuItem._id}>
  //           <Link className="dropdown-item" to={`/${subMenuItem.sublink}`}>
  //             {subMenuItem.subtitle}
  //           </Link>
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  // const handleMenuClick = (index) => {
  //   const newData = [...data];
  //   newData[index].showDropdown = !newData[index].showDropdown;
  //   setData(newData);
  // };
  const eventsRef = useRef(null);
  // useEffect(() => {
  //   const hash = window.location.hash;
  //   if (hash === '#events') {
  //     scrollToEvents();
  //   }
  // }, []);
  // const scrollToEvents = () => {
  //   if (eventsRef.current) {
  //     eventsRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  const handleScrollToEvents = () => {
    
    // const eventsSection = document.getElementById('events');
    // if (eventsSection) {
    //   eventsSection.scrollIntoView({ behavior: 'smooth' });
    // }
    const eventsSection = document.getElementById('events');
    if (eventsSection) {
      eventsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const renderMenu = () => {
    return (
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center align-items-center">
      {headerMenu.map((menuItem) => (
        <li key={menuItem.id} className={`nav-item ${menuItem ? 'dropdown' : ''}`}>
          {menuItem.attributes.title === 'Events' ? (
            // <a className="nav-link" href="#events" onClick={scrollToEvents}>
            //   {menuItem.attributes.title}
            // </a>
            <Link
            className="nav-link"
            to={`${
              menuItem && menuItem.attributes && menuItem.attributes.url
            }`}
            onClick={handleScrollToEvents}
          >
            {menuItem.attributes.title}
          </Link>
          ) : (
            <Link
              className="nav-link"
              to={`${
                menuItem && menuItem.attributes && menuItem.attributes.url
              }`}
            >
              {menuItem && menuItem.attributes && menuItem.attributes.title}{' '}
              {menuItem &&
                menuItem.attributes &&
                (menuItem.attributes.title === 'Products' ||
                  menuItem.attributes.title === 'Technologies') && (
                  <i
                    className={`fa-sharp fa-solid fa-caret-down ${
                      menuItem.showDropdown ? 'arrow-up' : 'arrow-down'
                    }`}
                  ></i>
                )}
            </Link>
          )}
          {menuItem &&
              menuItem.attributes &&
              menuItem.attributes.title == "Products" && (
                <ul
                  style={{ background: "#E0000D" }}
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {product &&
                    product.map((submenu) => (
                      <li key={submenu.id}>
                        <a 
                          className="dropdown-item"
                          href={`${
                            submenu &&
                            submenu.attributes &&
                            submenu.attributes.slug
                          }`}
                        >
                          {submenu &&
                            submenu.attributes &&
                            submenu.attributes.title}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
            {menuItem &&
              menuItem.attributes &&
              menuItem.attributes.title == "Technologies" && (
                <ul
                  style={{ background: "#E0000D" }}
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {technologies &&
                    technologies.map((submenu) => (
                      <li key={submenu.id}>
                        <a 
                          className="dropdown-item"
                          href={`${
                            submenu &&
                            submenu.attributes &&
                            submenu.attributes.slug
                          }`}
                        >
                          {submenu &&
                            submenu.attributes &&
                            submenu.attributes.title}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
        </li>
      ))}
    </ul>
      // <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center align-items-center">
      //   {headerMenu.map((menuItem) => (
      //     <li
      //       key={menuItem.id}
      //       className={`nav-item ${menuItem ? "dropdown" : ""}`}
      //     >
      //       <Link
      //         className="nav-link"
      //         to={`${
      //           menuItem && menuItem.attributes && menuItem.attributes.url
      //         }`}
      //       >
      //         {menuItem && menuItem.attributes && menuItem.attributes.title}{" "}
      //         {menuItem &&
      //           menuItem.attributes &&
      //           menuItem.attributes.title == "Products" && (
      //             <i
      //               className={`fa-sharp fa-solid fa-caret-down ${
      //                 menuItem.showDropdown ? "arrow-up" : "arrow-down"
      //               }`}
      //             ></i>
      //           )}
      //         {menuItem &&
      //           menuItem.attributes &&
      //           menuItem.attributes.title == "Technologies" && (
      //             <i
      //               className={`fa-sharp fa-solid fa-caret-down ${
      //                 menuItem.showDropdown ? "arrow-up" : "arrow-down"
      //               }`}
      //             ></i>
      //           )}
      //       </Link>
      //       {menuItem &&
      //         menuItem.attributes &&
      //         menuItem.attributes.title == "Products" && (
      //           <ul
      //             style={{ background: "#E0000D" }}
      //             className="dropdown-menu"
      //             aria-labelledby="dropdownMenuButton"
      //           >
      //             {product &&
      //               product.map((submenu) => (
      //                 <li key={submenu.id}>
      //                   <a 
      //                     className="dropdown-item"
      //                     href={`${
      //                       submenu &&
      //                       submenu.attributes &&
      //                       submenu.attributes.slug
      //                     }`}
      //                   >
      //                     {submenu &&
      //                       submenu.attributes &&
      //                       submenu.attributes.title}
      //                   </a>
      //                 </li>
      //               ))}
      //           </ul>
      //         )}
      //       {menuItem &&
      //         menuItem.attributes &&
      //         menuItem.attributes.title == "Technologies" && (
      //           <ul
      //             style={{ background: "#E0000D" }}
      //             className="dropdown-menu"
      //             aria-labelledby="dropdownMenuButton"
      //           >
      //             {technologies &&
      //               technologies.map((submenu) => (
      //                 <li key={submenu.id}>
      //                   <a 
      //                     className="dropdown-item"
      //                     href={`${
      //                       submenu &&
      //                       submenu.attributes &&
      //                       submenu.attributes.slug
      //                     }`}
      //                   >
      //                     {submenu &&
      //                       submenu.attributes &&
      //                       submenu.attributes.title}
      //                   </a>
      //                 </li>
      //               ))}
      //           </ul>
      //         )}
      //     </li>
      //   ))}


      //   {/* {data.map((menuItem, index) => (
      //     <li
      //       key={menuItem._id}
      //       className={`nav-item ${
      //         menuItem.submenu[0]?.submenuItems.length > 0 ? "dropdown" : ""
      //       }`}
      //     >
      //       <Link
      //         className="nav-link"
      //         to={
      //           menuItem.submenu[0]?.submenuItems.length > 0
      //             ? `${menuItem.link}`
      //             : `/${menuItem.link}`
      //         }
      //         onClick={() => handleMenuClick(index)}
      //       >
      //         {menuItem.title}{" "}
      //         {menuItem.submenu[0]?.submenuItems.length > 0 && (
      //           <i
      //             className={`fa-sharp fa-solid fa-caret-down ${
      //               menuItem.showDropdown ? "arrow-up" : "arrow-down"
      //             }`}
      //           ></i>
      //         )}
      //       </Link>
      //       {menuItem.showDropdown &&
      //         menuItem.submenu.length > 0 &&
      //         renderSubMenu(menuItem.submenu[0].submenuItems)}
      //     </li>
      //   ))}  */}
      // </ul>
    );
  };

  return <>{headerMenu.length > 0 ? renderMenu() : ""}</>;
}

export default Nav;
