import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";

function Termsofuse() {
  const [termsData, setTermsData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/terms-of-use?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setTermsData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const content =
    termsData &&
    termsData.data &&
    termsData.data.attributes &&
    termsData.data.attributes.content;
  const bannerImage =
    termsData &&
    termsData.data &&
    termsData.data.attributes &&
    termsData.data.attributes.bannerImage &&
    termsData.data.attributes.bannerImage.data.attributes &&
    termsData.data.attributes.bannerImage.data.attributes.url;
  return (
    <div>
     <SEO 
       title={
        termsData &&
        termsData.data &&
        termsData.data.attributes &&
        termsData.data.attributes.metaTitle
      }
      description={
        termsData &&
        termsData.data &&
        termsData.data.attributes &&
        termsData.data.attributes.metaDescription
      }
      />
      <Layout>
        <div className="hero_shape position-relative">
          <div
            style={{
              backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "542px",
              // backgroundRepeat: "no-repeat"
            }}
            className="d-flex align-items-center justify-content-center text-center"
          >
            <div className="container bg_text">
              <h1>
                {" "}
                {termsData &&
                  termsData.data &&
                  termsData.data.attributes &&
                  termsData.data.attributes.bannerTitle}
              </h1>
              <p>
                <a href="">Home</a>
                {termsData &&
                  termsData.data &&
                  termsData.data.attributes &&
                  termsData.data.attributes.bannerText}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Layout>
    </div>
  );
}

export default Termsofuse;
