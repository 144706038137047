import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import HeaderOne from "../common/header/HeaderOne";
import Footer from "../common/footer/Footer";
import GetinTouch from "../elements/GetinTouch";
import { strapi_base_url } from "../utils/base_url";

function Careers() {
  const [careerData, setCareerData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/career?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setCareerData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const content =
    careerData &&
    careerData.data &&
    careerData.data.attributes &&
    careerData.data.attributes.content;
  const bannerImage =
    careerData &&
    careerData.data &&
    careerData.data.attributes &&
    careerData.data.attributes.bannerImage &&
    careerData.data.attributes.bannerImage.data[0] &&
    careerData.data.attributes.bannerImage.data[0].attributes &&
    careerData.data.attributes.bannerImage.data[0].attributes.url;

  return (
    <div>
      <SEO
        title={
          careerData &&
          careerData.data &&
          careerData.data.attributes &&
          careerData.data.attributes.metaTitle
        }
        description={
          careerData &&
          careerData.data &&
          careerData.data.attributes &&
          careerData.data.attributes.metaDescription
        }
      />
      <>
        <main className="page-wrapper">
          <HeaderOne
            btnStyle="btn-small round btn-icon"
            HeaderSTyle="header-not-transparent"
          />
          <div className="hero_shape position-relative">
            <div
              style={{
                backgroundImage: `url(${strapi_base_url}${bannerImage})`,
                height: "542px",
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
              className="d-flex align-items-center justify-content-center text-center"
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                  zIndex: 1, // Ensure overlay is above the background image
                }}
              />
              <div
                className="container bg_text"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h1>
                  {careerData &&
                    careerData.data &&
                    careerData.data.attributes &&
                    careerData.data.attributes.bannerTitle}
                </h1>
                <p>
                  <a href="/">Home</a>
                  {careerData &&
                    careerData.data &&
                    careerData.data.attributes &&
                    careerData.data.attributes.bannerText}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <GetinTouch />
          <Footer />
        </main>
      </>
    </div>
  );
}

export default Careers;
