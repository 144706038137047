import React, { useEffect, useState } from "react";
import "../../css/style.css";
import { strapi_base_url } from "../../utils/base_url";

function Footer() {
  const [footerData, setFooterData] = useState([]);
  const [companyMenu, setCompanyMenu] = useState([]);
  const [technologyMenu, setTechnologyMenu] = useState([]);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/footer?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setFooterData(responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/menus/?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setCompanyMenu(
          responseData.data[2] &&
            responseData.data[2].attributes &&
            responseData.data[2].attributes
        );
        setTechnologyMenu(
          responseData.data[1] &&
            responseData.data[1].attributes &&
            responseData.data[1].attributes
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(`${strapi_base_url}/api/products?populate=`);
      const productData = await response.json();
      setProduct(productData.data);
    } catch (error) {
      console.error("Error fetching service pages:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const footerLogo =
    footerData.attributes &&
    footerData.attributes.footerLogo &&
    footerData.attributes.footerLogo.data[0] &&
    footerData.attributes.footerLogo.data[0].attributes &&
    footerData.attributes.footerLogo.data[0].attributes.url;

  return (
    <footer>
      <div className="container lat_footer">
        <div className="row footer_mg  ms-sm-0 ms-5">
          {/* Logo Section */}

          <div className="col-3 col-sm-12 col-md-3 col-lg-3 col-12 footer_m ">
            <div>
              {/* Image */}
              <img src={`${strapi_base_url}${footerLogo}`} alt="checkepoint" />
              {/* Address */}
              {/* {Array.isArray(data) &&
                data.length > 0 &&
                data.map((section, index) => ( */}
              <ul className="address_checkpoint mt-2">
                <li>
                  <a href="#section-footer">
                    {/* {section.FooterContacts && ( */}
                    {footerData.attributes && (
                      <div
                        dangerouslySetInnerHTML={{
                          // __html: section.FooterContacts,
                          __html:
                            footerData.attributes &&
                            footerData.attributes.address,
                        }}
                      ></div>
                    )}
                  </a>
                </li>
              </ul>
              {/* ))} */}
              <ul className="d-flex ps-0 mt-2 gap-3">
                <li className="footer_social">
                  <a href="#">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li className="footer_social">
                  <a href="#">
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                <li className="footer_social">
                  <a href="#">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Title One Section */}
          {/* {Array.isArray(data) &&
            data.length > 0 &&
            data.slice(1).map((section, index) => (
              <div
                key={index}
                className="col-3 col-sm-12 col-md-3 col lg-3 col-12  footer_m"
              >
                <>
                  {section.title && <h3>{section.title}</h3>}
                  {/* Rendering submenuItems */}
          {/* {section.submenu &&
                    section.submenu.map((submenu, submenuIndex) => (
                      <div key={submenuIndex} className="footer_link">
                        <ul>
                          {submenu.submenuItems.map((item, idx) => (
                            <li key={idx}>
                              <a href={`/${item.sublink}`}>{item.subtitle}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </>
              </div>
            ))}  */}

          <div className="col-3 col-sm-12 col-md-3 col lg-3 col-12  footer_m">
            {companyMenu && <h3>{companyMenu.title}</h3>}
            <div className="footer_link">
              <ul>
                {companyMenu.items &&
                  companyMenu.items.data.map((submenu, submenuIndex) => (
                    <li key={submenuIndex}>
                      <a href={`${submenu.attributes.url}`}>
                        {submenu.attributes.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-3 col-sm-12 col-md-3 col lg-3 col-12  footer_m">
            <h3>Products</h3>
            <div className="footer_link">
              <ul>
                {product &&
                  product.map((submenu, submenuIndex) => (
                    <li key={submenuIndex}>
                      <a href={`${submenu.attributes.slug}`}>
                        {submenu.attributes.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-3 col-sm-12 col-md-3 col lg-3 col-12  footer_m">
            {technologyMenu && <h3>{technologyMenu.title}</h3>}
            <div className="footer_link">
              <ul>
                {technologyMenu.items &&
                  technologyMenu.items.data.map((submenu, submenuIndex) => (
                    <li key={submenuIndex}>
                      <a href={`${submenu.attributes.url}`}>
                        {submenu.attributes.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="border-bottom mt-4"></div>
          <div className="text-center foot_tech mt-4 text-white">
            <p>
              © 2024 Copyright Checkpoint Technologies, LLC. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
