import React, { useEffect } from 'react'
import SEO from '../common/SEO'
import "../css/style.css"
import "../css/example.css"
import Layout from '../common/Layout'
function DefaultService({pageData}) {
    useEffect(() => {
        console.log('Received page data:', pageData);
      }, [pageData]);  
    
  return (
    <div>
    
     <SEO title="Product"/>
     <Layout>   
        <div>
          <div dangerouslySetInnerHTML={{ __html: pageData.editedContent}}/>
               </div>
        </Layout>
      
      </div>
  )
}

export default DefaultService