import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";

function About() {
  const [data2, setData2] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/about?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setData2(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const content =
    data2 &&
    data2.data &&
    data2.data.attributes &&
    data2.data.attributes.content;
  const bannerImage =
    data2 &&
    data2.data &&
    data2.data.attributes &&
    data2.data.attributes.bannerImage &&
    data2.data.attributes.bannerImage.data[0] &&
    data2.data.attributes.bannerImage.data[0].attributes &&
    data2.data.attributes.bannerImage.data[0].attributes.url;
  return (
    <div>
      <SEO
        title={
          data2 &&
          data2.data &&
          data2.data.attributes &&
          data2.data.attributes.metaTitle
        }
        description={
          data2 &&
          data2.data &&
          data2.data.attributes &&
          data2.data.attributes.metaDescription
        }
      />
      <Layout>
        <div className="hero_shape position-relative">
          <div
            style={{
              backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "936px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
            className="d-flex align-items-center justify-content-center text-center"
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                zIndex: 1, // Ensure overlay is above the background image
              }}
            />
            <div
              className="container bg_text"
              style={{ position: "relative", zIndex: 2 }}
            >
              <h1>
                {" "}
                {data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerTitle}
              </h1>
              <p>
                <a href="/">Home</a>
                {data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerText}
              </p>
            </div>
          </div>
          <img src="img/shape.png" alt="asd" />
        </div>

        {/* blog_details */}
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Layout>
    </div>
  );
}

export default About;
