import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
// import { base_url } from "../utils/base_url";
import { strapi_base_url } from "../utils/base_url";
// import ReactMarkdown from "react-markdown";

function About() {
  // const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  // useEffect(() => {
  //   fetch(`${base_url}getaboutpage`)
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //       setData(responseData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });

  // }, []);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/about?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setData2(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // if (!Array.isArray(data) || data.length === 0) {

  //   return;
  // }
  // const AboutData = data[0];
  // const bannerImage = AboutData && AboutData.bannerImage && AboutData.bannerImage.path.trimStart('/');
  // return (
  //   <div>

  //       <SEO title={AboutData.pageMetatitle}/>
  //        <Layout>
  //        <div className="hero_shape position-relative">
  //       <div   style={{
  //               backgroundImage: `url(${base_url}${bannerImage.replace('\\', '/')})`,
  //                 height: "542px",
  //        }} className="  d-flex align-items-center justify-content-center text-center">
  //           <div className="container bg_text ">
  //               <h1>{AboutData.title}</h1>
  //               <p style={{fontSize : '20px'}}><a href="/">Home</a> / About Us</p>
  //               <p>{AboutData.bannerText}</p>
  //               <p>{AboutData.bannerTitle}</p>
  //           </div>
  //       </div>
  //       <img src="img/shape.png" alt="asd"/>
  //   </div>

  // {/* blog_details */}
  //             <div  >
  //               <div dangerouslySetInnerHTML={{ __html: AboutData.editedContent || ''}}/>
  //                </div>

  //     </Layout>

  //   </div>

  // )
  const content =
    data2 &&
    data2.data &&
    data2.data.attributes &&
    data2.data.attributes.content;
  const bannerImage =
    data2 &&
    data2.data &&
    data2.data.attributes &&
    data2.data.attributes.bannerImage &&
    data2.data.attributes.bannerImage.data.attributes &&
    data2.data.attributes.bannerImage.data.attributes.url;
  return (
    <div>
      <SEO
        title={
          data2 &&
          data2.data &&
          data2.data.attributes &&
          data2.data.attributes.metaTitle

        }
        description={data2 &&
          data2.data &&
          data2.data.attributes &&
          data2.data.attributes.metaDescription}
      />
      <Layout>
        <div className="hero_shape position-relative">
          <div
            style={{
              backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "542px",
              width:"100%",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover", 
              backgroundPosition:"center",
            }}
            className="  d-flex align-items-center justify-content-center text-center"
          >
            <div className="container bg_text ">
              <h1>
                {" "}
                {data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerTitle}
              </h1>
              <p><a href="/">Home</a>
                {data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerText}
              </p>
            </div>
          </div>
          <img src="img/shape.png" alt="asd" />
        </div>

        {/* blog_details */}
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {/* <div>
            <ReactMarkdown>{content}</ReactMarkdown>
          </div> */}
        {/* </div> */}
      </Layout>
    </div>
  );
}

export default About;
