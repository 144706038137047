// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#topheader .navbar-nav li > a {
	text-transform: capitalize;
	color: #333;
	transition: background-color .2s, color .2s;
	
	&:hover,
	&:focus {
		background-color: #333;
		color: #fff;
	}
}

#topheader .navbar-nav li.active > a {
	background-color: #333;
	color: #fff;
}





.new-pane.fade {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.new-pane.fade.show {
  opacity: 1;
}
.new-pane {
  /* Your common styles for tab panes go here */
  display: none;
}

.new-pane.active {
  /* Styles for the active tab pane go here */
  display: block;
}



`, "",{"version":3,"sources":["webpack://./src/css/example.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,WAAW;CACX,2CAA2C;;CAE3C;;EAEC,sBAAsB;EACtB,WAAW;CACZ;AACD;;AAEA;CACC,sBAAsB;CACtB,WAAW;AACZ;;;;;;AAMA;EACE,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ;AACA;EACE,6CAA6C;EAC7C,aAAa;AACf;;AAEA;EACE,2CAA2C;EAC3C,cAAc;AAChB","sourcesContent":["#topheader .navbar-nav li > a {\r\n\ttext-transform: capitalize;\r\n\tcolor: #333;\r\n\ttransition: background-color .2s, color .2s;\r\n\t\r\n\t&:hover,\r\n\t&:focus {\r\n\t\tbackground-color: #333;\r\n\t\tcolor: #fff;\r\n\t}\r\n}\r\n\r\n#topheader .navbar-nav li.active > a {\r\n\tbackground-color: #333;\r\n\tcolor: #fff;\r\n}\r\n\r\n\r\n\r\n\r\n\r\n.new-pane.fade {\r\n  opacity: 0;\r\n  transition: opacity 0.3s ease-in-out;\r\n}\r\n\r\n.new-pane.fade.show {\r\n  opacity: 1;\r\n}\r\n.new-pane {\r\n  /* Your common styles for tab panes go here */\r\n  display: none;\r\n}\r\n\r\n.new-pane.active {\r\n  /* Styles for the active tab pane go here */\r\n  display: block;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
