import React, { useEffect, useState, useRef } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { Link } from "react-router-dom";
import { base_url } from "../utils/base_url";
import { strapi_base_url } from "../utils/base_url";

function HomeDefault() {
  const eventsRef = useRef(null);
  // const [data, setData] = useState([]);
  // const [logo, setLogo] = useState([]);

  // useEffect(() => {
  //   fetch(`${base_url}getHomePage`)
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //       setData(responseData);
  //     })
  //     .catch((error) => {});

  //   fetch(`${base_url}getAllLogoImage`)
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //       setLogo(responseData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching logo data:", error);
  //     });
  // }, []);

  // if (!Array.isArray(data) || data.length === 0) {
  //   return;
  // }

  // const bannerData = data[0];
  // const bannerImage =
  //   bannerData &&
  //   bannerData.bannerImage &&
  //   bannerData.bannerImage.path.trimStart("/");

  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/home?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHomeData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const content =
    homeData &&
    homeData.data &&
    homeData.data.attributes &&
    homeData.data.attributes.content;
  const bannerImage =
    homeData &&
    homeData.data &&
    homeData.data.attributes &&
    homeData.data.attributes.bannerImage &&
    homeData.data.attributes.bannerImage.data.attributes &&
    homeData.data.attributes.bannerImage.data.attributes.url;
  return (
    <div>
      <SEO
        title={
          homeData &&
          homeData.data &&
          homeData.data.attributes &&
          homeData.data.attributes.metaTitle
        }
        description={
          homeData &&
          homeData.data &&
          homeData.data.attributes &&
          homeData.data.attributes.metaDescription
        }
      />
      <Layout>
        <div
          style={{
            backgroundImage: `url(${strapi_base_url}${bannerImage})`,           
            height: "542px",
            width: "100%",
            backgroundRepeat:"no-repeat",
            backgroundSize:"cover",
            backgroundPosition:"center",           

          }}
          className="d-flex align-items-center"
        >
          <div className="container">
            <div className="bg_text">
              <h1 style={{ color: "white", width: "58%" }}>
                {homeData &&
                  homeData.data &&
                  homeData.data.attributes &&
                  homeData.data.attributes.bannerTitle}
              </h1>

              <p style={{ width: "53%" }}>
                {homeData &&
                  homeData.data &&
                  homeData.data.attributes &&
                  homeData.data.attributes.bannerText}
                <br />
              </p>
              <a href="#">Learn More →</a>
            </div>
          </div>
        </div>

        <div className="">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {/* <!-- Featured Insights & Events --> */}

      <div class="container events mt-5"  id="events" ref={eventsRef}>
          <h1 class="text-center">Featured Insights &amp; Events</h1>
          <div class="row">
              <div class="col-sm-12 col-md-4 col-lg-4 col-12 ">
                  <div class="card border-0 carousel_text">
                      <div class="hovereffect">
                          <img src="img/Rectangle 10.png" class="img-fluid"/>
                      </div>
                      <div class="card-body px-0">
                          <h3><a href="#">ISTFA 2023</a></h3>
                          <p><a href="#"> March 20 - 23
                                  Town and Country San DiegoSan Diego, CA
                                  Visit us at Booth #802 </a></p>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4 col-12">
                  <div class="card border-0 carousel_text">
                      <div class="hovereffect">
                          <img src="img/Rectangle 11.png" class="img-fluid"/>
                      </div>
                      <div class="card-body px-0">
                          <h3><a href="#">IPFA 2023</a></h3>
                          <p><a href="#">July 24 - 27 , 2023
                                  Bayview Beach Resort,Batu Ferringhi,
                                  Penang, Malaysia</a></p>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4 col-12 ">
                  <div class="card border-0 carousel_text">
                      <div class="hovereffect">
                          <img src="img/Rectangle 11 (1).png" class="img-fluid"/>
                      </div>
                      <div class="card-body px-0">
                          <h3><a href="#">ISTFA 2023</a></h3>
                          <p><a href="#">March 20 - 23
                                  Town and Country San DiegoSan Diego, CA
                                  Visit us at Booth #80</a></p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </Layout>
    </div>
  );
}

export default HomeDefault;
