import React, { useEffect } from 'react'
import SEO from '../common/SEO'
// import "../css/style.css"
// import "../css/example.css"
import Layout from '../common/Layout'
 
function DefaultPage({ pageData }) {

        useEffect(() => {
        console.log('Received page datasdfasdf:', pageData);
        }, [pageData]);  
   

  return (
 
    <div>
   
    <SEO title={pageData.pageMetatitle}/>
    <Layout>     
 <div style={{margin : 'auto'}}  className="d-flex align-items-center">
     <div className="container">
         <div className="bg_text"> 
           {/* <h3>{pageData.title}</h3> */}
               {pageData.bannerTitle}
             <p>{pageData.bannerText}</p>    
         </div>
     </div>
 </div>
    
   {/*our-prdct-section*/}

      <div>
        <div dangerouslySetInnerHTML={{ __html: pageData.editedContent}}/>
           </div>
      </Layout>
 
    </div>
  )
}

export default DefaultPage