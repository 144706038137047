import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/style.css";
import Nav from "./Nav";
import { base_url } from "../../utils/base_url";
import { strapi_base_url } from "../../utils/base_url";
function HeaderOne() {
  // const [logo, setLogo] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`${base_url}getAllLogoImage`)
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //       setLogo(responseData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching logo data:", error);
  //     });
  // }, []);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/header?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHeaderData(responseData.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const headerLogo =
    headerData.attributes &&
    headerData.attributes.headerLogo &&
    headerData.attributes.headerLogo.data &&
    headerData.attributes.headerLogo.data.attributes &&
    headerData.attributes.headerLogo.data.attributes.url;

  return (
    <header>
      {loading ? (
        <div>Loading...</div>
        ) : (
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container containerp">
          <Link className="navbar-brand" to="/">
            <img src={`${strapi_base_url}${headerLogo}`} alt="checkepoint" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <Nav />
            <div className=" nav_btn display d-lg-flex d-md-block text-lg-start text-center">
              <a className="btn_contact" href="Contact">
                Contact Us
              </a>

              <a className="nav-link" href="tel:+415-910-5009">
                <i className="fa-solid fa-phone pe-1"></i>
                {headerData.attributes && headerData.attributes.contactNumber}
              </a>
            </div>
          </div>
        </div>
      </nav>
      )}
    </header>
  );
}

export default HeaderOne;
