import React, { useEffect } from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";

function DefaultPage({ pageData }) {
  useEffect(() => {
  }, [pageData]);

  return (
    <div>
      <SEO title={pageData.pageMetatitle} />
      <Layout>
        <div style={{ margin: "auto" }} className="d-flex align-items-center">
          <div className="container">
            <div className="bg_text">
              {pageData.bannerTitle}
              <p>{pageData.bannerText}</p>
            </div>
          </div>
        </div>

        {/*our-prdct-section*/}

        <div>
          <div dangerouslySetInnerHTML={{ __html: pageData.editedContent }} />
        </div>
      </Layout>
    </div>
  );
}

export default DefaultPage;
