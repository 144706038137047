import React, { useEffect } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";
function ProductTwo({ technologyData }) {
  useEffect(() => {}, [technologyData]);

  const technologyImage =
    technologyData.technologyImage &&
    technologyData.technologyImage.data &&
    technologyData.technologyImage.data.attributes &&
    technologyData.technologyImage.data.attributes.url;

  return (
    <div>
      <SEO
        title={
          technologyData.title
        }
        description={
          "Checkpoint || Technologies"
        }
      />
      <Layout>
        {technologyImage && (
          <div className="container container_bg d-flex justify-content-center">
            <div className="img_infra d-flex justify-content-center">
              <img
                src={`${strapi_base_url}${technologyImage}`}
                className="mt-md-5 mt-0 img-fluid"
                alt="Tech Image"                
              />
            </div>
          </div>
        )}
        <div
          className="container infra_heading p-4"
          style={{ backgroundColor: "#fdf3f2" }}
        >
          <h1>{technologyData.title}</h1>
          <p>{technologyData.description}</p>
        </div>
      </Layout>
    </div>
  );
}

export default ProductTwo;
