import React, { useEffect, useState, useRef } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";

function HomeDefault() {
  const eventsRef = useRef(null);
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/home?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHomeData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const content =
    homeData &&
    homeData.data &&
    homeData.data.attributes &&
    homeData.data.attributes.content;
  const bannerImage =
    homeData &&
    homeData.data &&
    homeData.data.attributes &&
    homeData.data.attributes.bannerImage &&
    homeData.data.attributes.bannerImage.data[0] &&
    homeData.data.attributes.bannerImage.data[0].attributes &&
    homeData.data.attributes.bannerImage.data[0].attributes.url;

  return (
    <div>
      <SEO
        title={
          homeData &&
          homeData.data &&
          homeData.data.attributes &&
          homeData.data.attributes.metaTitle
        }
        description={
          homeData &&
          homeData.data &&
          homeData.data.attributes &&
          homeData.data.attributes.metaDescription
        }
      />
      <Layout>
        <div
          style={{
            backgroundImage: `url(${strapi_base_url}${bannerImage})`,
            height: "867px",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundOrigin: "content-box",
            position: "relative",
          }}
          className="d-flex align-items-center"
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
              zIndex: 1, // Ensure overlay is above the background image
            }}
          />
          <div
            className="container"
            style={{ position: "relative", zIndex: 2 }}
          >
            <div className="bg_text">
              <h1 style={{ color: "white", width: "58%" }}>
                {homeData &&
                  homeData.data &&
                  homeData.data.attributes &&
                  homeData.data.attributes.bannerTitle}
              </h1>

              <p style={{ width: "53%" }}>
                {homeData &&
                  homeData.data &&
                  homeData.data.attributes &&
                  homeData.data.attributes.bannerText}
                <br />
              </p>
              <a href="#">Learn More →</a>
            </div>
          </div>
        </div>

        <div className="">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {/* <!-- Featured Insights & Events --> */}

        <div class="container events mt-5" id="events" ref={eventsRef}>
          <h1 class="text-center">Featured Insights &amp; Events</h1>
          <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4 col-12 ">
              <div class="card border-0 carousel_text">
                <div class="hovereffect">
                  <img
                    src="img/ISTFA24_Carousel-1536x376.png"
                    className="event-img"
                  />
                </div>
                <div class="card-body px-0">
                  <h3>
                    <a href="#">ISTFA 2025</a>
                  </h3>
                  <p>
                    <a href="#">
                      {" "}
                      March 20 - 23 Town and Country San DiegoSan Diego, CA
                      Visit us at Booth #802{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-12">
              <div class="card border-0 carousel_text">
                <div class="hovereffect">
                  <img src="img/IPFA.jpg" className="event-img" />
                </div>
                <div class="card-body px-0">
                  <h3>
                    <a href="#">IPFA 2025</a>
                  </h3>
                  <p>
                    <a href="#">
                      July 24 - 27 , 2023 Bayview Beach Resort,Batu Ferringhi,
                      Penang, Malaysia
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-12 ">
              <div class="card border-0 carousel_text">
                <div class="hovereffect">
                  <img src="img/ESREF.jfif" className="event-img" />
                </div>
                <div class="card-body px-0">
                  <h3>
                    <a href="#">ESREF 2025</a>
                  </h3>
                  <p>
                    <a href="#">
                      March 20 - 23 Town and Country San DiegoSan Diego, CA
                      Visit us at Booth #80
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-12 ">
              <div class="card border-0 carousel_text">
                <div class="hovereffect">
                  <img src="img/gomactech.jpg" className="event-img" />
                </div>
                <div class="card-body px-0">
                  <h3>
                    <a href="#">GOMAC 2025</a>
                  </h3>
                  <p>
                    <a href="#">
                      March 20 - 23 Town and Country San DiegoSan Diego, CA
                      Visit us at Booth #80
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default HomeDefault;
