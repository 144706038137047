import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";
import React, { useEffect, useState } from "react";

function Pressreleases() {
  const [preReleaseData, setPreReleaseData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/pre-release?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setPreReleaseData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const content =
    preReleaseData &&
    preReleaseData.data &&
    preReleaseData.data.attributes &&
    preReleaseData.data.attributes.content;
  const bannerImage =
    preReleaseData &&
    preReleaseData.data &&
    preReleaseData.data.attributes &&
    preReleaseData.data.attributes.bannerImage &&
    preReleaseData.data.attributes.bannerImage.data.attributes &&
    preReleaseData.data.attributes.bannerImage.data.attributes.url;

  return (
    <div>
      <SEO 
       title={
        preReleaseData &&
        preReleaseData.data &&
        preReleaseData.data.attributes &&
        preReleaseData.data.attributes.metaTitle
      }
      description={
        preReleaseData &&
        preReleaseData.data &&
        preReleaseData.data.attributes &&
        preReleaseData.data.attributes.metaDescription
      }
      />
      <Layout>
        <div className="hero_shape position-relative">
          <div
            style={{
              backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "542px",
            }}
            className="  d-flex align-items-center justify-content-center text-center"
          >
            <div className="container bg_text ">
              <h1>
                {" "}
                {preReleaseData &&
                  preReleaseData.data &&
                  preReleaseData.data.attributes &&
                  preReleaseData.data.attributes.bannerTitle}
              </h1>
              <p>
                <a href="">Home</a>
                {preReleaseData &&
                  preReleaseData.data &&
                  preReleaseData.data.attributes &&
                  preReleaseData.data.attributes.bannerText}
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Layout>
    </div>
  );
}

export default Pressreleases;
