import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { base_url } from "../utils/base_url";
import { strapi_base_url } from "../utils/base_url";

function Careers() {
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   fetch(`${base_url}getcreerspage`)
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //       setData(responseData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  // if (!Array.isArray(data) || data.length === 0) {

  //   return;
  // }
  // const pageData = data[0];
  // const bannerImage = pageData && pageData.bannerImage && pageData.bannerImage.path.trimStart('/');
  //   console.log("data",data);

  const [careerData, setCareerData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/career?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setCareerData(responseData);
        console.log("responseData", responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const content =
    careerData &&
    careerData.data &&
    careerData.data.attributes &&
    careerData.data.attributes.content;
  const bannerImage =
    careerData &&
    careerData.data &&
    careerData.data.attributes &&
    careerData.data.attributes.bannerImage &&
    careerData.data.attributes.bannerImage.data.attributes &&
    careerData.data.attributes.bannerImage.data.attributes.url;

  return (
    <div>
      <SEO
        title={
          careerData &&
          careerData.data &&
          careerData.data.attributes &&
          careerData.data.attributes.metaTitle
        }
        description={
          careerData &&
          careerData.data &&
          careerData.data.attributes &&
          careerData.data.attributes.metaDescription
        }
      />
      <Layout>
        {/* hero section */}
        {/* <div   style={{
            backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "542px",
         }} className="d-flex align-items-center">
      <div className="container Careers_text position-relative">
        <h1>{data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerTitle}</h1>
        
        <p>{data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerText}</p>
      </div>
    </div> */}

        <div className="hero_shape position-relative">
          <div
            style={{
              backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "542px",
              width: "100%",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover", 
              backgroundPosition:"center",
            }}
            className="d-flex align-items-center justify-content-center text-center"
          >
            <div className="container bg_text">
              <h1>
                {careerData &&
                  careerData.data &&
                  careerData.data.attributes &&
                  careerData.data.attributes.bannerTitle}
              </h1>
              <p>
                <a href="/">Home</a>
                {careerData &&
                  careerData.data &&
                  careerData.data.attributes &&
                  careerData.data.attributes.bannerText}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Layout>
    </div>
  );
}

export default Careers;
