import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeDefault from "./pages/HomeDefault";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Contact from "./elements/Contact";
import Pressreleases from "./elements/press-releases";
import Privacypolicy from "./elements/privacy-policy";
import Termsofuse from "./elements/terms-of-use";
// import Technologies from "./elements/technologies";
import DefaultTechnologies from "./pages/DefaultTechnologies";
import DefaultPage from "./pages/DefaultPage";
import Product from "./elements/product/Product";
import DefaultService from "./pages/DefaultService";
import { base_url } from "./utils/base_url";
import { strapi_base_url } from "./utils/base_url";
const App = () => {
  const [pageData, setPageData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [product, setProduct] = useState([]);
  const [Technologies, setTechnologies] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}getdefaultPage`);
        const pagesData = await response.json();
        setPageData(pagesData);
      } catch (error) {}
    };

    const fetchTechnologies = async () => {
      try {
        const response = await fetch(
          `${strapi_base_url}/api/technologies?populate=*`
        );
        const TechnologiesData = await response.json();
        setTechnologies(TechnologiesData.data);
      } catch (error) {
        console.error("Error fetching Technologies pages:", error);
      }
    };

    const getProducts = async () => {
      try {
        const response = await fetch(
          `${strapi_base_url}/api/products?populate=*`
        );
        const productData = await response.json();
        setProduct(productData.data);
      } catch (error) {
        console.error("Error fetching service pages:", error);
      }
    };

    const fetchDataService = async () => {
      try {
        const response = await fetch(`${base_url}getServicePage`);
        const serviceData = await response.json();

        setServiceData(serviceData);
      } catch (error) {
        console.error("Error fetching service pages:", error);
      }
    };

    getProducts();
    // fetchData();
    // fetchDataService();
    fetchTechnologies();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomeDefault />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/press-releases" element={<Pressreleases />} />
          <Route path="/Privacy-policy" element={<Privacypolicy />} />
          <Route path="/Terms-of-use" element={<Termsofuse />} />
          {/* <Route path="/technologies" element={<Technologies />} /> */}
          {pageData.map((page) => (
            <Route
              key={page._id}
              path={`/${page.Slug.split("/").pop()}`}
              element={
                pageData.some(
                  (dynamicPage) => dynamicPage.Slug === page.Slug
                ) ? (
                  <DefaultPage pageData={page} />
                ) : (
                  <DefaultPage />
                )
              }
            />
          ))}

          {/* {productData.map((page) => (
            <Route
              key={page._id}
              path={`/${page.Slug && page.Slug.split("/").pop()}`}
              element={
                productData.some(
                  (dynamicPage) => dynamicPage.Slug === page.Slug
                ) ? (
                  <Product productData={page} />
                ) : (
                  <Product />
                )
              }
            />
          ))} */}

          {product.map((page) => (
            <Route
              key={page.id}
              path={`/${page.attributes.slug}`}
              element={
                product.some(
                  (dynamicPage) =>
                    dynamicPage.attributes.slug == page.attributes.slug
                ) ? (
                  <Product productData={page.attributes} />
                ) : (
                  <Product />
                )
              }
            />
          ))}

          {Technologies.map((page) => (
            <Route
              key={page.id}
              path={`/${page.attributes.slug}`}
              element={
                Technologies.some(
                  (dynamicPage) =>
                    dynamicPage.attributes.slug == page.attributes.slug
                ) ? (
                  <DefaultTechnologies technologyData={page.attributes} />
                ) : (
                  <DefaultTechnologies />
                )
              }
            />
          ))}

          {serviceData.map((page) => (
            <Route
              key={page._id}
              path={`/service/${page.Slug?.split("/").pop()}`}
              element={
                serviceData.some(
                  (dynamicPage) => dynamicPage.Slug === page.Slug
                ) ? (
                  <DefaultService pageData={page} />
                ) : (
                  <DefaultService />
                )
              }
            />
          ))}
        </Routes>
      </Router>
    </>
  );
};

export default App;
